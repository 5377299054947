/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/button-has-type */
import React from 'react';
import s from './CategoryTree.scss';
import {classes as stylableClasses, cssStates} from './CategoryTree.st.css';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {Text, TextButton, TextButtonPriority} from 'wix-ui-tpa/cssVars';
import {ConditionalRender} from '../ConditionalRender/ConditionalRender';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {Experiments} from '../../../constants';
import {ICategory, ICategoryListItem} from '../../../types/category';
import {useCategories} from '../../hooks/useCategories';
import classNames from 'classnames';
import {CategoryListHeader} from './CategoryListHeader/CategoryListHeader';

export const CategoryTreeDataHook = {
  SectionTitle: 'category-tree-section-title',
  List: 'category-tree-list',
  BackButton: 'category-tree-back-button',
  CategoryHeader: 'category-tree-header',
  Container: 'category-tree-container',
  CategoryOption: {
    at: (i: number) => `category-option-${i}`,
  },
};

export const CategoryTree: React.FunctionComponent<IGalleryGlobalProps & IProvidedTranslationProps> = withGlobals(
  withTranslations()(({t, globals}: IGalleryGlobalProps & IProvidedTranslationProps) => {
    const htmlTag = globals.htmlTags.categoriesFiltersHtmlTag;
    const {experiments} = useExperiments();
    let categories: ICategoryListItem[];
    let currentCategory: ICategory;
    let listHeaderCategory: ICategoryListItem;
    let backButtonUrl: string;
    const enableNewCategoryList = experiments.enabled(Experiments.NewCategoryList);
    if (enableNewCategoryList) {
      const {
        categoryList,
        listHeaderCategory: listHeader,
        currentCategory: _currentCategory,
        backButtonUrl: _backButtonUrl,
      } = useCategories();
      listHeaderCategory = listHeader;
      categories = categoryList;
      currentCategory = _currentCategory;
      backButtonUrl = _backButtonUrl;
    } else {
      categories = globals.categories;
      currentCategory = globals.currentCategory;
    }

    const onCategoryClicked = (e, destinationCategoryId: string, destinationLink: string) => {
      const {handleCategoryClick} = globals;
      e.preventDefault();
      handleCategoryClick({destinationCategoryId, destinationLink});
    };

    return (
      <div className={s.container} data-hook={CategoryTreeDataHook.Container}>
        <ConditionalRender by={'gallery_showCategoriesTitle'}>
          {experiments.enabled(Experiments.GalleryA11yFixes) ? (
            <Text data-hook={CategoryTreeDataHook.SectionTitle} tagName={htmlTag}>
              <span className={s.sectionTitle}>{t('categoryTreeTitle')}</span>
            </Text>
          ) : (
            <h2 data-hook={CategoryTreeDataHook.SectionTitle} className={s.sectionTitle}>
              {t('categoryTreeTitle')}
            </h2>
          )}
        </ConditionalRender>
        {enableNewCategoryList && listHeaderCategory && (
          <ConditionalRender by={'gallery_showCategoryListHeader'} className={s.listHeader}>
            <CategoryListHeader onClick={onCategoryClicked} dataHook={CategoryTreeDataHook.CategoryHeader} />
          </ConditionalRender>
        )}
        <div data-hook={CategoryTreeDataHook.List} className={s.content}>
          <ul
            className={classNames(s.options, {
              [s.shouldIndent]: enableNewCategoryList && listHeaderCategory && backButtonUrl,
            })}>
            {categories.map((category, i) => (
              <li key={category.id} className={s.option}>
                <TextButton
                  data-hook={CategoryTreeDataHook.CategoryOption.at(i)}
                  as="a"
                  className={`${stylableClasses.option} ${cssStates({
                    activeCategory: currentCategory.id === category.id,
                  })}`}
                  priority={TextButtonPriority.secondary}
                  href={category.categoryUrl}
                  onClick={(event) => onCategoryClicked(event, category.id, category.categoryUrl)}>
                  {category.name === 'All Products' ? t('allProducts') : category.name}
                </TextButton>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  })
);
